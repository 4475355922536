<template>
  <div>
    <a-form-model ref="searchForm" :model="searchForm" class="search-form" v-bind="layout">
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="平台">
            <a-select v-model="searchForm.platform">
              <a-select-option v-for="item in platformList" :key="item.id">
                {{ item.platform }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="平台帐号">
            <a-input placeholder="请输入帐号" v-model.trim="searchForm.platform_id"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="tabs !== 'author'">
          <a-form-model-item :label="timeLabel">
            <a-range-picker
                :placeholder="['开始时间', '结束时间']"
                :allowClear="false"
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                inputReadOnly
                @change="onChangeDate"
                style="width:100%"
                v-model="searchForm.time"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="分组">
            <a-select v-model="searchForm.group_id">
              <a-select-option v-for="item in groupList" :key="item.group_id">
                {{ item.group_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="经销商名称">
            <a-input placeholder="请输入经销商名称" v-model.trim="searchForm.dealer_name"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="tabs == 'author'">
          <a-form-model-item label="创作者平台">
            <a-select v-model="searchForm.service_auth">
              <a-select-option v-for="item in creatorPlatformList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="tabs == 'author'">
          <a-form-model-item label="互动数据">
            <a-select v-model="searchForm.open_auth">
              <a-select-option v-for="item in openPlatformList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="tabs == 'author'">
          <a-form-model-item
              label="微信状态"
          >
            <a-select v-model="searchForm.wechat_auth">
              <a-select-option v-for="item in WeChatStatusList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div>
        <a-form-model-item class="order-madel">
          <div class="flex-wrap">
            <a-button class="ml-10p" type="success" @click="exportList">导出当前搜索条件数据</a-button>
            <a-button class="ml-10p" type="primary" @click="searchClick">搜索</a-button>
            <a-button class="ml-10p" type="dashed" @click="resetSearch">重置</a-button>
          </div>
        </a-form-model-item>
      </div>
    </a-form-model>

    <div class="operation-box_black" v-if="tabs == 'author' && isForm !== 'details'">
      经销商数量： <span style="color: #f5a60b;padding-right: 20px">{{ (countData || {}).dealer_count || 0 }}</span>
      媒体帐号数量：<span style="color:#f5a60b;padding-right: 20px">{{
        (countData || {}).author_count || 0
      }}</span>
      创作者平台授权异常：<span style="color:red;padding-right: 20px">{{
        (countData || {}).unauthorized_service_count || 0
      }}</span>
      互动数据授权异常：<span style="color:red;padding-right: 20px">{{ (countData || {}).unauthorized_open_count || 0 }}</span>
      微信授权异常：<span style="color:red;">{{ (countData || {}).unauthorized_wechat_count || 0 }}</span>
    </div>
    <div class="operation-box" v-else>结果数量：{{ total ? total : videoTotal }}条</div>
    <a-table
        :columns="columnsList.filter(item => item.filterType !== 'isHide')"
        :data-source="data_list"
        :pagination="false"
        :rowKey="(record, index) => index"
        :loading="loading"
        class="mt-40p"
    >
      <!-- 总览 -->
      <div slot="dataIndex" slot-scope="index,text, record">
        <div class="row-box box-1">
          <span> {{ parseInt(record) + 1 }}</span>
        </div>
      </div>
      <div slot="authorInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>昵</span><span>称：</span></div>
          <div>
            <div>{{ record.nickname || "--" }}</div>
          </div>
        </div>
        <div class="row-box box-1">
          <div><span>平</span><span>台：</span></div>
          <div>
            <span v-if="record.platform == 1">抖音</span>
            <span v-else-if="record.platform == 2">快手</span>
            <span v-else-if="record.platform == 3">懂车帝</span>
            <span v-else>--</span>
          </div>
        </div>
        <div class="row-box box-1">
          <div><span>平</span><span>台</span><span>账</span><span>号：</span></div>
          <div>{{ record.code || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>粉</span><span>丝</span><span>数：</span></div>
          <div>{{ record.fans_count_total || 0 }}</div>
        </div>
        <div class="row-box box-1" v-if="record.platform != 1">
          <div><span>点</span><span>赞</span><span>数：</span></div>
          <div>{{ record.like_count_total || 0 }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>作</span><span>品</span><span>数：</span></div>
          <div>{{ record.aweme_count_total || 0 }}</div>
        </div>
      </div>
      <div slot="dealerInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>名</span><span>称：</span></div>
          <div style="color: #2A90F4;cursor: pointer" @click="goToDealerPage(record)">{{
              record.dealer_name || "--"
            }}
          </div>
        </div>
        <div class="row-box box-1">
          <div><span>ID：</span></div>
          <div>{{ record.dealer_id || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>分组：</span></div>
          <div>{{ record.group_name || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>大区：</span></div>
          <div>{{ record.area || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>编号：</span></div>
          <div>{{ record.dealer_number ? record.dealer_number : record.store_code ? record.store_code : "--" }}</div>
        </div>
      </div>

      <div slot="statusRender" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>创作平台：</span></div>
          <div>
            <a-tag color="green" v-if="record.servie_auth_status == 1">已授权</a-tag>
            <a-tag color="red" v-else-if="record.servie_auth_status == 2">授权失效</a-tag>
            <a-tag color="red" v-else-if="record.servie_auth_status == 0">未授权</a-tag>
          </div>
        </div>
        <div class="row-box box-1" v-if="record.platform !== 3">
          <div><span>互动数据：</span></div>
          <div>
            <a-tag color="green" v-if="record.open_auth_status == 1">已授权</a-tag>
            <a-tag color="red" v-else-if="record.open_auth_status == 2">授权过期</a-tag>
            <a-tag color="purple" v-else-if="record.open_auth_status == 3">部分接口未授权，需要重新授权，补充授权项</a-tag>
            <a-tag color="purple" v-else-if="record.open_auth_status == 4">正在检查用户一致性</a-tag>
            <a-tag color="purple" v-else-if="record.open_auth_status == 5">帐号一致性检查过程报错</a-tag>
            <a-tag color="purple" v-else-if="record.open_auth_status == 6">需要授权的帐号与扫码帐号不一致</a-tag>
            <a-tag color="purple" v-else-if="record.open_auth_status == 0">未授权</a-tag>
          </div>
        </div>
        <div class="row-box box-1">
          <div><span>微信平台：</span></div>
          <div>
            <a-tag color="green" v-if="record.wechat_auth_state == 1">已绑定</a-tag>
            <a-tag color="red" v-if="record.wechat_auth_state == 0">未绑定</a-tag>
          </div>
        </div>
      </div>
      <div slot="authorServie" slot-scope="text, record">
        <div v-if="record.platform && record.platform !== 3">
          <a-tag color="green" v-if="record.servie_auth_status == 1">已授权</a-tag>
          <a-tag color="red" v-else-if="record.servie_auth_status == 2">授权失效</a-tag>
          <a-tag color="orange" v-else>未授权</a-tag>
        </div>
        <div v-else>--</div>
      </div>
      <div slot="authorOpen" slot-scope="text, record">
        <div v-if="record.platform && record.platform !== 3">
          <a-tag color="green" v-if="record.open_auth_status == 1">已授权</a-tag>
          <a-tag color="red" v-else-if="record.open_auth_status == 2">授权过期</a-tag>
          <a-tag color="purple" v-else-if="record.open_auth_status == 3">部分接口未授权，需要重新授权，补充授权项</a-tag>
          <a-tag color="purple" v-else-if="record.open_auth_status == 4">正在检查用户一致性</a-tag>
          <a-tag color="purple" v-else-if="record.open_auth_status == 5">帐号一致性检查过程报错</a-tag>
          <a-tag color="purple" v-else-if="record.open_auth_status == 6">需要授权的帐号与扫码帐号不一致</a-tag>
          <a-tag color="orange" v-else>未授权</a-tag>
        </div>
        <div v-else>--</div>
      </div>
      <div slot="operationAccount" slot-scope="text, record">
        <a @click="() => getPlatform(record)" style="display:block">查看诊断报告</a>
      </div>

      <!-- 直播 -->
      <div slot="livePlatform" slot-scope="text, record">
        <span v-if="record.platform == 1">抖音</span>
        <span v-else-if="record.platform == 2">快手</span>
        <span v-else-if="record.platform == 3">懂车帝</span>
        <span v-else>--</span>
      </div>
      <div slot="liveInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>直</span><span>播</span><span>标</span><span>题：</span></div>
          <div>{{ record.title || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>开</span><span>播</span><span>时</span><span>间：</span></div>
          <div>{{ record.publish_time || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>直</span><span>播</span><span>时</span><span>长：</span></div>
          <div>{{ record.statistics_live_time ? formatDuration(record.statistics_live_time) : "--" }}</div>
        </div>
      </div>
      <div slot="liveData" slot-scope="text, record">
        <div class="row-box box-1" v-if="record.platform === 1">
          <div><span>观</span><span>看</span><span>人</span><span>次：</span></div>
          <div>{{ record.watch_count || 0 }}</div>
        </div>
        <div class="row-box box-1" v-else>
          <div><span>观</span><span>看</span><span>人</span><span>数：</span></div>
          <div>{{ record.watch_count || 0 }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>在</span><span>线</span><span>峰</span><span>值：</span></div>
          <div>{{ record.statistics_max_online_user || 0 }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>评</span><span>论</span><span>人</span><span>数：</span></div>
          <div>{{ record.statistics_total_comment_user_count || 0 }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>送</span><span>礼</span><span>人</span><span>数：</span></div>
          <div>{{ record.statistics_total_gift_user_count || 0 }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>收</span><span>入：</span></div>
          <div>{{ record.statistics_total_ticket_count || 0 }}</div>
        </div>
      </div>
      <div slot="operationLive" slot-scope="text, record">
        <a @click="() => openVideo(record.play_url)" style="display:block"
           v-if="record.play_status == 'IA' || record.play_status == 'Standard'">查看回放</a>
        <span v-else-if="record.play_status == 'Unknown' || record.play_status == 'Archive'"
              style="color: #333333">链接冻结</span>
        <span v-else-if="!record.play_url" style="color: #333333">暂无回放</span>
        <a-button type="link" @click="gotoRoute(record)" :loading="record.__LOADING__" v-if="record.play_url">获取分享链接
        </a-button>
      </div>

      <!-- 视频 -->
      <div slot="dataIndex" slot-scope="index,text, record">
        <div class="row-box box-1">
          <span> {{ parseInt(record) + 1 }}</span>
        </div>
      </div>
      <div slot="videoPlatform" slot-scope="text, record">
        <span v-if="record.platform == 1">抖音</span>
        <span v-else-if="record.platform == 2">快手</span>
        <span v-else-if="record.platform == 3">懂车帝</span>
        <span v-else>--</span>
      </div>
      <div slot="videoInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>标</span><span>题：</span></div>
          <div>{{ record.aweme_title || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>发</span><span>布</span><span>时</span><span>间：</span></div>
          <div>{{ record.publish_time || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>视</span><span>频</span><span>ID：</span></div>
          <div>{{ record.aweme_id || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>使</span><span>用</span><span>模板：</span></div>
          <div>{{ record.module_name || "--" }}</div>
        </div>
      </div>
      <div slot="videoDataInfo" slot-scope="text, record">
        <div class="row-box box-2">
          <div style="width: 130px">较昨日新增播放量：</div>
          <div>{{ record.play_volumec_count_di || 0 }}</div>
        </div>
        <div class="row-box box-2">
          <div style="width: 130px">较昨日新增点赞量：</div>
          <div>{{ record.digg_count_di || 0 }}</div>
        </div>
        <div class="row-box box-2">
          <div style="width: 130px">较昨日新增评论量：</div>
          <div>{{ record.comment_count_di || 0 }}</div>
        </div>
        <div class="row-box box-2">
          <div style="width: 130px">较昨日新增转发量：</div>
          <div>{{ record.share_count_di || 0 }}</div>
        </div>
      </div>
      <div slot="videoData" slot-scope="text, record">
        <div class="row-box box-2">
          <div><span>播</span><span>放</span><span>量：</span></div>
          <div>{{ record.play_volume || 0 }}</div>
        </div>
        <div class="row-box box-2">
          <div><span>点</span><span>赞</span><span>量：</span></div>
          <div>{{ record.digg_count || 0 }}</div>
        </div>
        <div class="row-box box-2">
          <div><span>评</span><span>论</span><span>量：</span></div>
          <div>{{ record.comment_count || 0 }}</div>
        </div>
        <div class="row-box box-2">
          <div><span>转</span><span>发</span><span>量：</span></div>
          <div>{{ record.share_count || 0 }}</div>
        </div>
        <div class="row-box box-2">
          <div><span>完</span><span>播</span><span>率：</span></div>
          <div>{{
              record.play_total_count_completion_rate ? `${ (record.play_total_count_completion_rate * 100).toFixed(1) }%` : "--"
            }}
          </div>
        </div>
        <div class="row-box box-2">
          <div style="width: 90px"><span>视</span><span>频</span><span>状</span> <span>态：</span></div>
          <div>
            {{ record.aweme_status == 0 ? "不可见" : record.aweme_status == 1 ? "可见" : "--" }}
          </div>
        </div>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a @click="() => openVideo(record.aweme_url)" style="display:block" v-if="record.aweme_url">查看视频</a>
        <span v-else style="color: #333333">暂无视频</span>
      </div>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
        :currentPage="current"
        :pageSize="page_size"
        :total="total"
        @onChange="onChange"
        @onShowSizeChange="onChange"
    >
    </base-pagination>
  </div>
</template>

<script>
import moment from "moment";
import utils from "@/utils/FormatUtil.js";
import buryEntry from "@/utils/buryEvent.js";
import _http from "@/api/base/baseService";

export default {
  name: "SelectInputModel",
  props: {
    timeLabel: {
      type: String,
      default: "更新时间"
    },
    tabs: {
      type: String,
      default: "author"
    },
    groupList: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    dataList: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => {
      }
    },
    countData: {
      type: Object,
      default: () => {
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    activityName: {
      type: String,
      default: ""
    },
    isForm: {
      type: String,
    }
  },
  data() {
    return {
      classifyList: [],
      creatorPlatformList: [
        {id: null, name: "全部"},
        {id: 0, name: "未授权"},
        {id: 1, name: "已授权"},
        {id: 2, name: "授权失效"}
      ],
      openPlatformList: [
        {id: null, name: "全部"},
        {id: 0, name: "未授权"},
        {id: 1, name: "已授权"},
        {id: 2, name: "授权过期"},
        {id: 3, name: "授权失败"},
        {id: 4, name: "授权审核中"}
        // {id: 5, name: '授权失败'},
        // {id: 6, name: '授权失败'},
      ],
      platformList: [
        {id: 1, platform: "抖音"},
        {id: 2, platform: "快手"},
        {id: 3, platform: "懂车帝"}
      ],
      searchForm: {
        // begin_time: this.tabs != 'author' ? moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm:ss') : '',
        begin_time: this.tabs != "author" ? this.beginTime() : "",
        end_time: this.tabs != "author" ? moment().endOf("day").format("YYYY-MM-DD HH:mm:ss") : "",
        time: this.tabs != "author" ? [this.beginTime(), moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")] : []
      },
      layout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16}
      },
      columnsList: [],
      loading: false,
      data_list: [],
      total: 0,
      current: 1,
      page_size: 10,
      WeChatStatusList: [
        {id: 0, name: "未绑定"},
        {id: 1, name: "已绑定"}
      ],
      videoTotal: 0
    };
  },
  watch: {
    columns: {
      handler: function(val) {
        if(val) {
          this.columnsList = val || [];
        }
      },
      immediate: true,
      deep: true
    },
    isLoading: {
      handler: function(val) {
        this.loading = val;
      },
      immediate: true
    },
    pagination: {
      handler: function(val) {
        console.log(val);
        if(val) {
          this.total = val.total || 0;
          this.current = val.current || 1;
          this.page_size = val.page_size || 10;
        }
      },
      immediate: true,
      deep: true
    },
    dataList: {
      handler: function(val) {
        this.loading = false;
        if(val) {
          this.data_list = val || [];
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    beginTime() {
      let time = moment().subtract(6, "day").format("YYYY-MM-DD");
      return time + " 00:00:00";
    },
    getPlatform(record) {
      let lastweek = utils.getLastWeek(1);
      if(record.servie_auth_status != 1) {
        this.$error({
          title: "创作者平台未授权！请先进行授权"
        });
      } else {
        const paths = this.$router.resolve({
          path: `/training/diagnostic`,
          query: {
            activity_name: this.activityName,
            platform: record.platform,
            author_id: record.author_id,
            start_time: lastweek[0] + " 00:00:00",
            end_time: lastweek[1] + " 23:59:59"
          }
        });
        window.open(paths.href, "_blank");
      }
    }, // 生成诊断报告

    // 视频查看
    openVideo(url) {
      const parm = "\u003cscript\u003elocation.replace(\"" + url + "\")\u003c/script\u003e";
      const str = "javascript:window.name;";
      window.open(str, parm);
      // window.open(url);
    },
    // 重置选择条件
    resetSearch() {
      this.searchForm = {
        begin_time: this.beginTime(),
        end_time: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        time: [this.beginTime(), moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")]
      };
      this.isDongCheDi();
      this.$emit("resetList", this.searchForm);
    },
    // 搜索
    searchClick() {
      this.isDongCheDi();
      this.$emit("searchList", Object.assign(this.searchForm, {current: this.current, page_size: this.page_size}));
    },
    // 导出
    exportList() {
      this.$emit("exportExcel", Object.assign(this.searchForm, {current: this.current, page_size: this.page_size}));
      buryEntry("activity", "download-current");
    },
    isDongCheDi() {
      if(this.searchForm.platform === 3) {
        this.columnsList.map(item => {
          if(item.filterType) {
            item.filterType = "isHide";
          }
        });
      } else {
        this.columnsList.map(item => {
          if(item.filterType) {
            item.filterType = "isShow";
          }
        });
      }
    },
    //日期选择框
    onChangeDate(dates) {
      let begin_time = "";
      let end_time = "";
      if(dates[0]) {
        begin_time = this.$moment(dates[0]._d).format("YYYY-MM-DD HH:mm:ss");
        end_time = this.$moment(dates[1]._d).format("YYYY-MM-DD HH:mm:ss");
      }
      this.searchForm.end_time = end_time;
      this.searchForm.begin_time = begin_time;
    },
    onChange(current, pageSize) {
      this.$emit("onChange", Object.assign(this.searchForm, {current: current, page_size: pageSize}));
    },
    formatDuration(number) {
      if(!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${ h }时${ m }分`;
      }
    },
    gotoRoute(row) {
      this.$set(row, "__LOADING__", true);
      // 获取分享链接
      let params = {
        room_id: row.room_id
      };
      _http.getUrl(params).then((res) => {
        if(res.code == 200) {
          let copyUrl = res.data.page_url || "";
          let aux = document.createElement("input");
          aux.setAttribute("value", copyUrl);
          document.body.appendChild(aux);
          aux.select();
          document.execCommand("copy");
          console.log(document.execCommand("copy"));
          if(document.execCommand("copy")) {
            this.$message.success("链接已复制成功");
          } else {
            this.$message.error("该浏览器不支持自动复制");
          }
          document.body.removeChild(aux);
        } else {
          this.$message.error(res.message);
        }
        this.$set(row, "__LOADING__", false);
      });
    }, // 获取分享链接 复制
    goToDealerPage(record) {
      console.log(record);
      this.$router.push({
        name: "dealer-detail",
        query: {
          dealer_id: record.dealer_id,
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.order-madel {
  display: flex;
  justify-content: flex-end;

}

.flex-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.ml-10p {
  margin-left: 10px;
}

.flex-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.ml-10p {
  margin-left: 10px;
}

.row-box {
  display: flex;
  margin-bottom: 5px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: bold;

    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  & > div:last-child {
    flex: 1;
    min-width: 50px;
  }
}

.box-1 {
  & > div:first-child {
    width: 70px;
  }
}

.box-2 {
  & > div:first-child {
    width: 60px;
  }
}

.operation-box {
  margin: 20px 15px;
  color: #f5a60b;
}

.operation-box_black {
  margin: 20px 15px;
}
</style>
