<template>
  <a-card>
    <a-form-model ref="searchForm" :model="searchForm" class="search-form" v-bind="layout">
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="KPI名称">
            <a-input placeholder="请输入" v-model.trim="searchForm.kpi_name"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="KPI考核时间">
            <a-range-picker
                :placeholder="['开始时间', '结束时间']"
                :allowClear="false"
                format="YYYY-MM-DD"
                inputReadOnly
                @change="onChangeDate"
                style="width:100%"
                v-model="searchForm.time"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <div>
        <a-form-model-item class="order-madel">
          <div class="flex-wrap">
            <a-button class="ml-10p" type="primary" @click="searchClick">搜索</a-button>
            <a-button class="ml-10p" type="dashed" @click="resetSearch">重置</a-button>
          </div>
        </a-form-model-item>
      </div>
    </a-form-model>
    <a-table
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :rowKey="(record, index) => index"
        :loading="isLoading"
        class="mt-40p"
    >
      <div slot="start_time" slot-scope="text, record">
        <span>{{ record.start_time }} - {{ record.end_time }}</span>
      </div>
      <div slot="module_ids" slot-scope="text, record">
        <span>{{ record.module_ids == 0 ? "否" : record.module_ids == 1 ? "是" : "-" }}</span>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a style="display:block" @click="() => goDetail(record)">详情</a>
      </div>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        @onChange="onChange"
        @onShowSizeChange="onChange"
    >
    </base-pagination>
  </a-card>
</template>

<script>

export default {
  name: "kpi_list",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    activityId: {
      type: [String, Number],
    },
    principalId: {
      type: [String, Number],
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          total: 0,
          current: 1,
          page_size: 10,
        };
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isLoading: {
      handler: function(val) {
        this.loading = val;
      },
      immediate: true
    },
  },
  data() {
    return {
      layout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16}
      },
      searchForm: {
        time: [],
        kpi_name: "",
        start_time: "",
        end_time: "",
      },
      loading: false,
      columns: [
        {
          dataIndex: "name",
          align: "center",
          title: "KPI名称"
        },
        {
          dataIndex: "start_time",
          align: "center",
          scopedSlots: {customRender: "start_time"},
          title: "考核时间"
        },
        {
          dataIndex: "module_ids",
          align: "center",
          scopedSlots: {customRender: "module_ids"},
          title: "是否使用模板"
        },
        {
          dataIndex: "kpi_count",
          align: "center",
          title: "考核指标"
        },
        {
          dataIndex: "modifier",
          align: "center",
          title: "更新人"
        },
        {
          dataIndex: "mtime",
          align: "center",
          title: "更新时间"
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {customRender: "operation"},
          width: 150
        }
      ]
    };
  },
  methods: {
    // 搜索
    searchClick() {
      this.$emit("searchKPIList", Object.assign(this.searchForm, {
        current: this.pagination.current,
        page_size: this.pagination.page_size
      }));
    },
    // 重置选择条件
    resetSearch() {
      this.searchForm = {
        time: [],
        kpi_name: ""
      };
      this.$emit("resetKPIList", this.searchForm);
    },
    onChangeDate(dates) {
      let start_time = "";
      let end_time = "";
      console.log(dates);
      if(dates[0]) {
        start_time = this.$moment(dates[0]._d).format("YYYY-MM-DD") + " 00:00:00";
        end_time = this.$moment(dates[1]._d).format("YYYY-MM-DD") + " 23:59:59";
      }
      this.searchForm.start_time = start_time;
      this.searchForm.end_time = end_time;
    },
    onChange(current, pageSize) {
      this.$emit("onChange", Object.assign(this.searchForm, {current: current, page_size: pageSize}));
    },
    goDetail(record) {
      console.log(this.activityId, this.principalId);
      this.$router.push({
        name: "kpi_detail",
        query: {
          id: record.id,
          activity_id: this.activityId,
          principal_id: this.principalId,
        }
      });
    },
  }
};
</script>

<style scoped lang="less">

.order-madel {
  display: flex;
  justify-content: flex-end;

}

.flex-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.ml-10p {
  margin-left: 10px;
}

</style>
